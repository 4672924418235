import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './FlipCard.css';

const FlipCard = ({ image, title, detailedDescription }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleHoverStart = () => {
        setIsFlipped(true);
    };

    const handleHoverEnd = () => {
        setIsFlipped(false);
    };

    const handleTouchStart = () => {
        setIsFlipped(!isFlipped);
    };
    return (
        <div className="flip-card-container" onTouchStart={handleTouchStart}>
            <motion.div className="flip-card">
                <motion.div
                    className="flip-card-inner"
                    onHoverStart={handleHoverStart}
                    onHoverEnd={handleHoverEnd}
                    animate={{ rotateY: isFlipped ? 180 : 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <motion.div className="flip-card-front">
                        <img src={image} alt="Card Front" className="card-image" />
                        <div className="flip-card-overlay">
                            <p>{title}</p>
                        </div>
                    </motion.div>
                    <motion.div className="flip-card-back">
                        <div className="detailed-description">
                            <p>{detailedDescription}</p>
                        </div>
                        <div className="free-course-play-btn">
                            <button>Play Course</button>
                        </div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default FlipCard;
