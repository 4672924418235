import React, { useEffect, useState } from 'react';
import './FreeCourseMain.css';
import courseImage from '../../assets/images/free-course-dummy.jpg'
import FlipCard from '../FlipCard/FlipCard';

const courseData = {
    Writing: [
        { title: 'Writing - Lesson 1', description: 'Description of Writing Lesson 1', image: courseImage },
        { title: 'Writing - Lesson 2', description: 'Description of Writing Lesson 2', image: courseImage },
        { title: 'Writing - Lesson 1', description: 'Description of Writing Lesson 1', image: courseImage },
        { title: 'Writing - Lesson 2', description: 'Description of Writing Lesson 2', image: courseImage },
    ],
    Reading: [
        { title: 'Reading - Lesson 1', description: 'Description of Reading Lesson 1', image: courseImage },
        { title: 'Reading - Lesson 2', description: 'Description of Reading Lesson 2', image: courseImage },
        { title: 'Writing - Lesson 1', description: 'Description of Writing Lesson 1', image: courseImage },
        { title: 'Writing - Lesson 2', description: 'Description of Writing Lesson 2', image: courseImage },
    ],
    Speaking: [
        { title: 'Speaking - Lesson 1', description: 'Description of Speaking Lesson 1', image: courseImage },
        { title: 'Speaking - Lesson 2', description: 'Description of Speaking Lesson 2', image: courseImage },
        { title: 'Writing - Lesson 1', description: 'Description of Writing Lesson 1', image: courseImage },
        { title: 'Writing - Lesson 2', description: 'Description of Writing Lesson 2', image: courseImage },
    ],
};

function FreeCourseMain() {
    const [activeTab, setActiveTab] = useState('Writing');
    const [bgColor, setBgColor] = useState('white');

    const handleTabClick = (course) => {
        setActiveTab(course);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const maxScroll = document.body.scrollHeight - window.innerHeight;
            const scrollFraction = scrollY / maxScroll;
            const startColor = { r: 255, g: 255, b: 255 };
            const endColor = { r: 248, g: 244, b: 241 };
            const newColor = {
                r: Math.round(startColor.r + (endColor.r - startColor.r) * scrollFraction),
                g: Math.round(startColor.g + (endColor.g - startColor.g) * scrollFraction),
                b: Math.round(startColor.b + (endColor.b - startColor.b) * scrollFraction),
            };
            const newBgColor = `rgb(${newColor.r}, ${newColor.g}, ${newColor.b})`;
            setBgColor(newBgColor);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="free-course-section" style={{ backgroundColor: bgColor }}>
            <div className="free-course-container">
                <div className="free-course-section-heading">
                    <h2>Unlimited access to boost your knowledge</h2>
                </div>
                <div className="free-course-tabs">
                    {Object.keys(courseData).map((course) => (
                        <div
                            key={course}
                            className={`course-tab ${activeTab === course ? 'active' : ''}`}
                            onClick={() => handleTabClick(course)}
                        >
                            {course}
                        </div>
                    ))}
                </div>
                <div className="free-course-cards">
                    {courseData[activeTab].map((card, index) => (
                        <FlipCard
                            key={index}
                            image={card.image}
                            title={card.title}
                            detailedDescription={card.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FreeCourseMain;
