import React from 'react'
import GrammarVideos from '../GrammarVideos'
import FooterDashboard from '../../FooterDashboard/FooterDashboard'

const StudyMaterialMain = () => {
  return (
    <div className='studymaterialmain-section'>
        <GrammarVideos />
        <FooterDashboard />
    </div>
  )
}

export default StudyMaterialMain
