import React from 'react'
import { useNavigate } from 'react-router-dom';

function ProfileIcon() {
    const navigate = useNavigate();
    function handleProfileClick() {
        navigate('/profile');
    }

    return (
        <>
            <p onClick={handleProfileClick} className="profile-icon material-symbols-outlined">
                account_circle
            </p>
        </>
    )
}

export default ProfileIcon