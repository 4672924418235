import React, { useState } from 'react';
import './AssignmentInnerDetailsMain.css';

function AssignmentInnerDetailsMain() {

    const [selectedFile, setSelectedFile] = useState(null);

  // Function to handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Function to handle upload button click
  const handleUploadClick = () => {
    if (!selectedFile) {
      alert("Please upload a file before submitting.");
      return;
    }
   
    console.log("File uploaded:", selectedFile);
  };


  return (
    <div className='assignment-inner-details-main'>
      <div className="assignment-heading-upload-btn">
        <p className="assignment-inner-heading">Assignment - 1: Introduction to Grammar</p>
        <button className="assignment-upload-btn" onClick={handleUploadClick}>Upload</button>
      </div>
      
      <div className="assignment-inner-details-explanation">
        <p>This assignment covers the principles of English Grammar. You are required to submit your assignment in one of the following formats:</p>
        <ul>
          <li>PDF or Word Document</li>
          <li>Image (JPEG, PNG)</li>
          <li>Video (MP4, AVI)</li>
        </ul>
      </div>
      
      <form className="assignment-upload-form" onClick={() => document.getElementById('file-upload').click()}>
        <label htmlFor="file-upload">Click to upload your assignment</label>
        <input type="file" id="file-upload" accept=".pdf,.docx,.jpeg,.png,.mp4,.avi" onChange={handleFileChange} />
        {selectedFile && <p>Selected file: {selectedFile.name}</p>}
        <p className="upload-instructions">Accepted formats: PDF, DOC, Image, Video</p>
      </form>
    </div>
  );
}

export default AssignmentInnerDetailsMain;
