import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./HeroSection.css";

function HeroSection() {
    const [animate, setAnimate] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    useEffect(() => {
        setAnimate(true);
    }, []);

    return (
        <div className="hero-Section">
            {!isMobile ? (
                // Desktop View Layout
                <>
                    <div className={`first-half ${animate ? 'slide-up appear' : ''}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <div className="banner-text">
                            <h1 className="text1"><strong>WATCH,</strong></h1>
                            <h1 className="text2"><strong>LEARN,</strong></h1>
                            <h1 className="text3"><strong>GROW.</strong></h1>
                        </div>
                        <div className="buttons">
                            <button className="btn">FREE DEMO</button>
                            <button className="btn">EXPLORE</button>
                        </div>
                    </div>
                    <div className={`second-half ${animate ? 'slide-right appear' : ''}`}>
                        <div className="card">
                            {[
                            {
                                img: "https://images.pexels.com/photos/2381069/pexels-photo-2381069.jpeg?auto=compress&cs=tinysrgb&w=600.jpg",
                                title: "A",
                                topics: "10 Topics",
                            },
                            {
                                img: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600.jpg",
                                title: "B",
                                topics: "8 Topics",
                            },
                            {
                                img: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600.jpg",
                                title: "C",
                                topics: "8 Topics",
                            }

                        ].map((course, index) => (
                                <div key={index} className="card1">
                                    <img src={course.img} alt={course.title} className="mobile-card-img" />
                                    {/* <div className="overlay-text1">
                                        <h2 className="h2-text"><strong>{course.title}</strong></h2>
                                    </div> */}
                                    <div className="overlay-text2">
                                        <h2 className="h2-text"><strong>{course.title}</strong></h2>
                                        <hr className="bannerhr" />
                                        <h4 className="h4-text">{course.topics}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                // Mobile View Layout
                <div className="mobile-hero-section">
                    <div className={`mobile-first-half ${animate ? 'fade-in' : ''}`}>
                        <h1 className="mobile-banner-text">
                            <strong>WATCH, LEARN, GROW</strong>
                        </h1>
                        <div className="mobile-buttons">
                            <button className="btn mobile-btn">FREE DEMO</button>
                            <button className="btn mobile-btn">EXPLORE</button>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection: 'row', }} className={`mobile-cards ${animate ? 'fade-in-cards' : ''}`}>
                        {[
                            {
                                img: "https://images.pexels.com/photos/2381069/pexels-photo-2381069.jpeg?auto=compress&cs=tinysrgb&w=600.jpg",
                                title: "Course A",
                                topics: "10 Topics",
                            },
                            {
                                img: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600.jpg",
                                title: "Course B",
                                topics: "8 Topics",
                            },
                            {
                                img: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg?auto=compress&cs=tinysrgb&w=600.jpg",
                                title: "Course C",
                                topics: "8 Topics",
                            }

                        ].map((course, index) => (
                            <div key={index} className="mobile-card">
                                <img src={course.img} alt={course.title} className="mobile-card-img" />
                                <div className="mobile-card-content">
                                    <h2 className="mobile-card-title">{course.title}</h2>
                                    <p className="mobile-card-topics">{course.topics}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default HeroSection;
