import React from 'react'
import { SideBar } from '../../components/SideBar/SideBar'
import ProfileSection from '../../components/ProfileSection/ProfileSection'

const ProfileSectionPage = () => {
  return (
    <div style={{display:'flex'}} className='profile-section-page'>
        <SideBar />
        <ProfileSection />
      
    </div>
  )
}

export default ProfileSectionPage
