import React from 'react'
import { SideBar } from '../../components/SideBar/SideBar'
import StudentMainSection from '../../components/StudentMainSection/StudentMainSection'


function StudentDashboard() {
  return (
    <div style={{display:'flex'}} className="student-dashboard-container">
      <SideBar />
      <StudentMainSection />
    </div>
  )
}

export default StudentDashboard