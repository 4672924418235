import React from 'react'
import './AssignmentPage.css'
import { SideBar } from '../../components/SideBar/SideBar'
import AssignmentMain from '../../components/AssignmentMain/AssignmentMain'

function AssignmentPage() {
  return (
    <div style={{display:'flex'}} className='assignment-page-container'>
        <SideBar />
        <AssignmentMain />
    </div>
  )
}

export default AssignmentPage