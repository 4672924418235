import React from "react";
import "./FooterDashboard.css";

const FooterDashboard = () => {
  return (
    <footer className="footer">

      <div className="footer-bottom">
        <p>© 2024 Accent Aura</p>
        <div className="footer-legal">
          <a href="#privacy">Privacy Policy</a>
          <a href="#terms">Terms & Conditions</a>
          <a href="#accessibility">Accessibility</a>
        </div>
      </div>
    </footer>
  );
};

export default FooterDashboard;
