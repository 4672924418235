import React from 'react'
import './AssignmentCard.css'
import { useNavigate } from 'react-router-dom'

function AssignmentCard() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/assignmentdetails')
  }

  return (
    <div className='assignmentcard-section'>
        <div className="assignmentcard-container">
            <div className="assignmentcard-header">
                <p className='assignment-name'>Assignment Name</p>
                <p className='assignment-date'>Due Date<span>: 20-10-2024</span></p>
            </div>
            <div className="assignment-view-btn">
                <button onClick={handleClick}>View Details</button>
            </div>
        </div>
    </div>
  )
}

export default AssignmentCard