import React from 'react'
import './DiscussionPage.css'
import { SideBar } from '../../components/SideBar/SideBar'
import DiscussionMainComponent from '../../components/DiscussionMainComponent/DiscussionMainComponent'

function DiscussionPage() {
  return (
    <div style={{display:'flex'}} className="faq-page-container">
        <SideBar />
        <DiscussionMainComponent />
    </div>
  )
}

export default DiscussionPage