import React from "react";
import { SideBar } from "../../components/SideBar/SideBar";
import StudyMaterialMain from "../../components/StudyMaterialComponents/StudyMaterialMain/StudyMaterialMain";


const StudyMaterial = () => {
  return (
    <div  className="studymaterial-section">
      <div style={{display:'flex'}} className="main-section">
        <SideBar />
        <StudyMaterialMain />
      </div>
    </div>
  );
};

export default StudyMaterial;
