import { useMutation } from "@tanstack/react-query";
import { signupStudent } from "../../services/restApiAuth";
import { useQueryClient } from "@tanstack/react-query";
import { useActivateUser } from "./useActivateUser";
import { useState } from "react";
import OtpModal from "../Otp/OtpModal"; 

export function useSignup() {
  const queryClient = useQueryClient();
  const { activateUser, isLoading: isActivateLoading } = useActivateUser();
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [activationToken, setActivationToken] = useState(null);

  const { mutate: signup, isLoading } = useMutation({
    mutationFn: signupStudent,
    onSuccess: (data) => {
      setActivationToken(data.activationToken); 
      setIsModalOpen(true); 
    },
  });

  const handleOtpSubmit = (otp) => {
    queryClient.setQueryData(["otp"], otp);
    activateUser({ getActivationtoken: activationToken, getOtp: otp });
    setIsModalOpen(false); 
  };

  return {
    signup,
    isLoading,
    isModalOpen,
    handleOtpSubmit,
    closeModal: () => setIsModalOpen(false),
  };
}
