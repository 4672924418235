import React, { useEffect, useState } from 'react';
import './StudentMainSection.css';
import StudentAnimatedBackground from '../StudentAnimatedBackground/StudentAnimatedBackground';
import { getCurrentUser } from '../../services/restApiAuth';
import { useSchedules } from '../../components/hooks/useSchedules';
import FooterDashboard from '../FooterDashboard/FooterDashboard';
import CalendarComponent from '../CalendarComponent/CalendarComponent';
import EnrolledCourse from '../EnrolledCourse/EnrolledCourse';
import AssignedTrainerCard from '../AssignedTrainerCard/AssignedTrainerCard';
import AttendanceGraph from '../AttendanceGraph/AttendanceGraph';
import { useNavigate } from 'react-router-dom';
import ProfileIcon from '../ProfileIcon/ProfileIcon';



const StudentMainSection = () => {
  const [events, setEvents] = useState([]);
  const [username, setUsername] = useState('');
  const [upcomingMeeting, setUpcomingMeeting] = useState(null);
  const { scheduleMethod, isLoading } = useSchedules();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getCurrentUser();
        setUsername(userData.user.name);
        scheduleMethod(
          { schedules: userData.user.schedules },
          {
            onSuccess: (scheduleData) => {
              console.log(scheduleData.data);
              const now = new Date();
              const upcoming = scheduleData.data
                .filter((classItem) => new Date(classItem.start_time) > now)
                .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))[0]; 
              setUpcomingMeeting(upcoming);
            },
          }
        );
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, []);

  function handleProfileClick() {
    navigate('/profile');
  }


  return (
    <div className="student-main-section">
      <div className="student-main-container">
        <div className="heading-bell-container">
          <p className="student-welcome-heading">Welcome! {username}</p>
          <div className="profile-notification-container">
            <p className="bell-icon material-symbols-outlined">notifications</p>
            <ProfileIcon />
          </div>
        </div>
        <div className="student-upcoming-events">
          <StudentAnimatedBackground meeting={upcomingMeeting} />
        </div>
        <div className="Slot-calendar-section">
          <div className="calendar-and-table">
            <div className="Basic-section">
              <div className="basic-section-content">
                <EnrolledCourse enrolledCourse={upcomingMeeting} />
                <AssignedTrainerCard trainerName = {upcomingMeeting} />
              </div>
              <div className="attendance-circle-graph">
                <AttendanceGraph />
              </div>
            </div>
            <div className="calendar-section">
              <CalendarComponent />
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default StudentMainSection;
