import React from 'react'
import './AssignmentMain.css'
import AssignmentCard from '../AssignmentCard/AssignmentCard'
import { Typography } from '@mui/material'
import ProfileIcon from '../ProfileIcon/ProfileIcon'

function AssignmentMain() {
    return (
        <div className='assignmentmain-section'>
            <div className="assignmentmain-container">
                <div className="heading-bell-container">
                    <p className="student-welcome-heading">Assignments</p>
                    <div className="profile-notification-container">
                        <p className="bell-icon material-symbols-outlined">notifications</p>
                        <ProfileIcon />
                    </div>

                </div>
                <div className="assignment-main-cards-container">
                    <AssignmentCard />
                </div>
            </div>
        </div>
    )
}

export default AssignmentMain