import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <div className="footer-subscribe">
          <p>Subscribe to be the first to know <br /> about our latest courses</p>

          <div className="footer-subscribe-input">
            <input type="email" placeholder="Email address" />
            <button>→</button>
          </div>
          <div className="footer-social">
            <div className="facebook">
              <a href="https://facebook.com" aria-label="Facebook">
                <i className="fab fa-facebook"></i>
              </a>
            </div>
            <div className="twitter">
              <a href="https://twitter.com" aria-label="Twitter">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
            <div className="instagram">
              <a href="https://instagram.com" aria-label="Instagram">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <a href="#cancellation">Cancellation Policy</a>
          <a href="#quick-links">Quick Links</a>
          <a href="#site-map">Site map</a>
          <a href="#faqs">FAQs</a>
          <a href="#contact">Contact Us</a>
        </div>
        <div className="footer-column">
          <a href="#about-us">About Us</a>
          <a href="#our-process">Our Process</a>
          <a href="#affiliate">Affiliate</a>
          <a href="#teach">Teach with Us</a>
          <a href="#trainee">Accent Aura Trainee</a>
        </div>
        <div className="footer-column1">
          <p>Language</p>
          <div className="English">
            <select>
              <option value="en">English</option>
            </select>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© 2024 Accent Aura</p>
        <div className="footer-legal">
          <a href="#privacy">Privacy Policy</a>
          <a href="#terms">Terms & Conditions</a>
          <a href="#accessibility">Accessibility</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
