import React from 'react';
import './AssignedTrainerCard.css';

function AssignedTrainerCard({trainerName}) {
    return (
        <div className="assigned-trainer-card">
            <p className="assigned-trainer-header">Assigned Trainer</p>
            <p className="trainer-name">{trainerName?.trainerId.split('@')[0].split('.')[0]}</p>
        </div>
    );
}

export default AssignedTrainerCard;
