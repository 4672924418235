const questionsData = [
    {
      id: 1,
      name: 'Navneet Kumar',
      question: 'What is the best way to learn a new language?',
    },
    {
      id: 2,
      name: 'John Doe',
      question: 'How can I improve my coding skills?',
    },
    {
      id: 3,
      name: 'Jane Smith',
      question: 'What are some good resources to learn React?',
    },
    // Add more questions as needed
  ];
  
  export default questionsData;
  