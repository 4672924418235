import React from 'react'
import './Promo.css'

function Promo() {
  return (
    <div className='promo-section'>
        <div className="promo-container">
            <div className="promo-heading-desc-container">
                <h2>Get the guidance and proficiency you need for communication to stand out</h2>
                <p>Speaking and communicating is crucial in these modern world but with right guidance and excellet IELTS tutor it's possible</p>
            </div>
            <div className="video-promo-slogan-container">
                <div className="promo-slogan-container">
                    <div className="slogan-logo">
                        <div className="slogan-logo-container">

                        </div>
                        <div className="dashed-connector"></div>
                        <div className="slogan-logo-container">
                            
                        </div>
                        <div className="dashed-connector"></div>
                        <div className="slogan-logo-container">
                            
                        </div>
                    </div>
                    <div className="slogan-para">
                        <p className="slogan-big">Leadership</p>
                        <p className="slogan-small">Fully committed to the success company</p>
                        <p className="slogan-big big">Responsibility</p>
                        <p className="slogan-small">We will take responsibilty to excel you</p>
                        <p className="slogan-big big">Flexibility</p>
                        <p className="slogan-small">We provide flexibility in courses</p>
                    </div>
                </div>
                <div className="promo-video-container">
                    <div className="course-number-container">
                        <p className='para-bold para-visible'>100</p>
                        <p className="course-number-description para-visible">
                            Live 
                            <br />
                            Sessions
                        </p>
                        <p className="para-bar para-visible">|</p>
                        <p className='para-bold'>150</p>
                        <p className="course-number-description">
                            Types of
                            <br />
                            Courses
                        </p>
                    </div>
                    <div className="promo-video-box">
                        <video src="https://videos.pexels.com/video-files/3201691/3201691-hd_1920_1080_25fps.mp4"  autoPlay loop></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Promo