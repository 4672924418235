import { useState } from 'react';

export const useForm = (initialState, callback) => {
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await callback();
    } catch (err) {
      setError("Password reset failed.");
    }
  };

  return { formData, handleChange, handleSubmit, error, setError };
};
