import React from 'react'
import { SideBar } from '../../components/SideBar/SideBar'
import AssignmentInnerDetailsMain from '../../components/AssignmentInnerDetailsMain/AssignmentInnerDetailsMain'

function AssignmentInnerDetails() {
  return (
    <div style={{display:'flex'}} className='assignment-inner-details'>
        <SideBar />
        <AssignmentInnerDetailsMain />
    </div>
  )
}

export default AssignmentInnerDetails