import { API_BASE_URL, TIMEOUT_SEC } from "./constant.js";
import axios from "axios";

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

export async function userLogin({ email, password }) {
  try {
    const url = `${API_BASE_URL}login`;
    const fetchAdminLogin = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([fetchAdminLogin, timeout(TIMEOUT_SEC)]);

    const data = await res.json();
    console.log(data);
    return data;
  } catch (err) {
    //
    console.log(err);
    throw new Error("Invalid email or pass");
  }
}

export async function signupStudent({ name, email, password }) {
    try {
      const url = `${API_BASE_URL}registration`;
      const registerUser = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, password }),
        credentials: "include", // Send cookies along with the request
      });
      const res = await Promise.race([registerUser, timeout(TIMEOUT_SEC)]);
  
      const data = await res.json();
      console.log(data);
      return data;
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  }

export async function userActivation({ activation_token: getActivationtoken, activation_code: getOtp  }) {
    try {
      const url = `${API_BASE_URL}activate-user`;
      const activateUser = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ activation_token: getActivationtoken, activation_code: getOtp  }),
        credentials: "include", // Send cookies along with the request
      });
      const res = await Promise.race([activateUser, timeout(TIMEOUT_SEC)]);
  
      const data = await res.json();
      console.log(data);
      return data;
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
}

export async function getCurrentUser() {
  try {
    //
    const uri = `${API_BASE_URL}me`;
    const registerUser = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await registerUser.json();
    console.log(data);
    return data;
  } catch (error) {
    //
    throw new Error(error.message);
  }
}

export async function logoutUser() {
  try {
    //
    const uri = `${API_BASE_URL}logout`;
    const res = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();
    return data;
  } catch (error) {
    //
    throw new Error("Unable to logout User.");
  }
}

export async function getTrainers() {
  try {
    //
    const uri = `${API_BASE_URL}trainers`;
    const trainers = await fetch(uri, {
      method: "GET",
      credentials: "include",
    });
    const data = await trainers.json();
    console.log(data);
    return data;
  } catch (error) {
    //
    throw new Error(error.message);
  }
}

export async function zoomAuth() {
  try {
    const uri = `http://localhost:3001/api/zoom`;
    const data = await axios.get(uri);
    console.log(data);
    return data.data.zoom_token;
  } catch (err) {
    console.log("ERROR", err);
    throw new Error("Zoom auth failed.");
  }
}

export async function ScheduleDemoForm({
  student_email,
  trainer_email,
  name,
  startTime,
  accessToken,
}) {
  try {
    // const accessToken =
    const url = `${API_BASE_URL}scheduleDemo`;
    const registerUser = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        trainer_email,
        name,
        student_email,
        startTime,
        accessToken,
      }),
      credentials: "include", // Send cookies along with the request
    });
    const res = await Promise.race([registerUser, timeout(TIMEOUT_SEC)]);

    const data = await res.json();
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
}