import { useMutation } from "@tanstack/react-query";
import {userActivation } from "../../services/restApiAuth";
import { QueryClient, useQueryClient } from "@tanstack/react-query";


export function useActivateUser() {
  const queryClient = useQueryClient();
//   const getActivationtoken = queryClient.getQueryData(["activationToken"]);
//   const getOtp = queryClient.getQueryData(["otp"]);
  const { mutate: activateUser, isLoading } = useMutation({
    mutationFn: ({getActivationtoken, getOtp}) => userActivation({ activation_token: getActivationtoken, activation_code: getOtp }),
    onSuccess: (data) => {
    },
  });

  return { activateUser, isLoading };
}