import React, { useState } from 'react';
import VideoCard from './VideoCard';
import VideoPlayer from './VideoPlayer';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Slide, Typography } from '@mui/material';

const GrammarVideos = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const videos = [
    {
      title: 'Grammar Basics',
      description: 'Introduction to basic grammar concepts.',
      thumbnail: 'https://images.pexels.com/photos/6503100/pexels-photo-6503100.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.jpg',
      url: 'https://cdn.pixabay.com/video/2018/11/13/19314-300877515_large.mp4'
    },
    {
      title: 'Advanced Grammar',
      description: 'Deep dive into advanced grammar topics.',
      thumbnail: 'https://images.pexels.com/photos/5792852/pexels-photo-5792852.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.jpg',
      url: 'https://cdn.pixabay.com/video/2015/10/07/905-141727230_medium.mp4'
    },
    {
      title: 'Moderate Grammar',
      description: 'Deep dive into advanced grammar topics.',
      thumbnail: 'https://images.pexels.com/photos/256417/pexels-photo-256417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.jpg',
      url: 'https://cdn.pixabay.com/video/2020/03/09/33415-396631693_large.mp4'
    },
    {
      title: 'Moderate Grammar',
      description: 'Deep dive into advanced grammar topics.',
      thumbnail: 'https://images.pexels.com/photos/256417/pexels-photo-256417.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.jpg',
      url: 'https://cdn.pixabay.com/video/2018/11/13/19314-300877515_large.mp4'
    },
    // Add more video objects as needed
  ];

  return (
    <Container sx={{ pt: 0, pb: 4 }}>
      {selectedVideo ? (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
          <div>
            <VideoPlayer video={selectedVideo} onBack={() => setSelectedVideo(null)} />
          </div>
        </Slide>
      ) : (
        <>
          <Slide direction="down" in={true} mountOnEnter unmountOnExit>
            <div className="heading-bell-container">
              <p className="student-welcome-heading">Study Materials</p>
              <div className="profile-notification-container">
                <p className="bell-icon material-symbols-outlined">notifications</p>
                <p className="profile-icon material-symbols-outlined">
                  account_circle
                </p>
              </div>
            </div>
          </Slide>
          <Grid container spacing={4}>
            {videos.map((video, index) => (
              <Grid item key={video.title} xs={12} sm={6} md={4}>
                <VideoCard video={video} onClick={setSelectedVideo} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  );

};

export default GrammarVideos;
