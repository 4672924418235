import {useNavigate} from 'react-router-dom'
import {useMutation , useQueryClient} from '@tanstack/react-query'
import {userLogin} from '../../services/restApiAuth'
import { fetchSchedules } from './apiSchedules';


export function useSchedules()
{
    const queryClient = useQueryClient();
    const {mutate:scheduleMethod,isLoading} = useMutation({
        mutationFn: ({schedules}) => {
            return fetchSchedules({schedules})
        },
        onSuccess: (studentMeetingSchedules) => {
          queryClient.setQueryData(["meetingSchedules"], studentMeetingSchedules);
        },
        onError: (error) => {
            console.log(error);
            alert(error.message);
        }
    });

    return {scheduleMethod,isLoading};
}