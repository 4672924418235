import React from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const VideoPlayer = ({ video, onBack }) => {
  return (
    <Container maxWidth="md">
      <Button variant="contained" color="primary" onClick={onBack} sx={{ mb: 2 }}>
        Back to Videos
      </Button>
      <video controls autoPlay style={{ width: '100%', height: 'auto', borderRadius: '8px' }}>
        <source src={video.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Typography variant="h4" component="h2" sx={{ mt: 2 }}>
        {video.title}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {video.description}
      </Typography>
    </Container>
  );
};

export default VideoPlayer;
