import React from 'react'
import './HomePage.css';
import HeroSection from '../../components/HeroSection/HeroSection';
import NavBar from '../../components/Navbar/Navbar';
import FreeCourseMain from '../../components/FreeCourseMain/FreeCourseMain';
import Promo from '../../components/PromotionSection/Promo';
import Footer from '../../components/Footer/Footer';

function HomePage() {
    return(
        <div className="Homepage-Container">
            <NavBar/>
            <HeroSection />
            <FreeCourseMain />
            <Promo />
            <Footer />

        </div>
    )
}
export default HomePage;