import { API_BASE_URL } from "../../services/constant";

export async function fetchSchedules({schedules}) {
    
    try {
        const url = `${API_BASE_URL}schedules/fetchSchedule`;
        const fetchSchedulesData = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ schedules }),
          credentials: "include", // Send cookies along with the request
        });
        
    
        const data = await fetchSchedulesData.json();
        console.log(data);
        return data;
      } catch (err) {
        //
        console.log(err);
        throw new Error("Invalid email or pass");
      }

}