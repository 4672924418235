import {useNavigate} from 'react-router-dom'
import {useMutation , useQueryClient} from '@tanstack/react-query'
// import {login as loginApi} from '../../services/apiAuth'
import {userLogin} from '../../services/restApiAuth'


export function useLogin()
{
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {mutate:login,isLoading} = useMutation({
        mutationFn: ({email,password}) => {
            return userLogin({email,password})
        },
        onSuccess: (user) => {
            queryClient.setQueryData(["user"], user);
            navigate('/studenthome')
        },
        onError: (error) => {
            console.log(error);
            alert(error.message);
        }
    });

    return {login,isLoading};
}