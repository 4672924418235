import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './QuestionDetail.css';

function QuestionDetail({ questions }) {
  const { questionId } = useParams(); // Get the ID from the URL
  const questionIndex = parseInt(questionId); // Convert the questionId to an integer

  // Check if questions are defined and find the question by index
  const question = questions && questions.length > questionIndex ? questions[questionIndex + 1] : null;

  const [replies, setReplies] = useState([
    { name: 'Alice', reply: 'You can use apps like Duolingo!' },
    { name: 'Bob', reply: 'Try immersive learning by watching movies in that language.' },
  ]);
  const [newReply, setNewReply] = useState('');

  const handleReplySubmit = () => {
    if (newReply.trim()) {
      setReplies([...replies, { name: 'Anonymous', reply: newReply }]);
      setNewReply(''); // Clear the input field
    }
  };

  if (!question) {
    return <p>Question not found.</p>; // Display a message if the question is not found
  }

  return (
    <div className="question-detail-container">
      <h2>{question.question}</h2>
      <p>Asked by {question.name}</p>

      <div className="replies-section">
        <h3>Replies</h3>
        {replies.map((r, index) => (
          <div key={index} className="reply">
            <p className='reply-name'><strong>{r.name}:</strong> {r.reply}</p>
          </div>
        ))}
      </div>

      <div className="reply-form">
        <textarea
          value={newReply}
          onChange={(e) => setNewReply(e.target.value)}
          placeholder="Type your reply here..."
        />
        <button onClick={handleReplySubmit}>Submit Reply</button>
      </div>
    </div>
  );
}

export default QuestionDetail;
