import React from 'react'
import './AttendanceGraph.css'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'

function AttendanceGraph() {
    return (
        <div className="attendance-details">
            <p>My Attendance</p>
            <div style={{ width: 150, height: 150 }}>
                <CircularProgressbar
                    value={80}
                    
                    styles={buildStyles({
                        textColor: '#0961A2',
                        pathColor: '#0961A2',
                        trailColor: '#d6d6d6',
                    })}
                />
            </div>
        </div>
    )
}

export default AttendanceGraph