import React from 'react';
import './EnrolledCourse.css';

function EnrolledCourse({ enrolledCourse }) {
    return (
        <div className="course-enrolled-card">
            <p className="course-enrolled-heading">Course Enrolled</p>
            <p className="course-name">{enrolledCourse?.course}</p>
        </div>
    );
}

export default EnrolledCourse;
