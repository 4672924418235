import React, { useEffect, useState } from 'react';
import './StudentAnimatedBackground.css';
import Ellipsis from '../Ellipsis/Ellipsis';

const StudentAnimatedBackground = ({ meeting }) => {
    // Unconditionally declare state and default values
    const [status, setStatus] = useState('Yet to Start');
    const [course, setCourse] = useState('');
    const [joinUrl, setJoinUrl] = useState('');
    const [startTime, setStartTime] = useState(null);

    // Use useEffect unconditionally, and update the state only when meeting is available
    useEffect(() => {
        if (meeting) {
            const { course, start_time, join_url } = meeting;
            setCourse(course);
            setJoinUrl(join_url);
            setStartTime(new Date(start_time));

            const checkStatus = () => {
                const now = new Date();
                const classStartTime = new Date(start_time);

                // Update status based on whether the class has started
                if (now >= classStartTime) {
                    setStatus('Started');
                } else {
                    setStatus('Yet to Start');
                }
            };

            checkStatus();

            // Update status every minute
            const intervalId = setInterval(checkStatus, 60000);

            // Cleanup interval on unmount
            return () => clearInterval(intervalId);
        }
    }, [meeting]);

    if (!meeting) {
        return <div>No upcoming class scheduled.</div>;
    }

    return (
        <div className="animated-background-section">
            <div className="context">
                <div className="upcoming-lecture-section">
                    <p><Ellipsis text={course} maxLength={50} /></p>

                    <div className="join-now-meeting-btn">
                        <button onClick={() => window.open(joinUrl, '_blank')} disabled={status === 'Yet to Start'}>
                            <div className="original">{status === 'Yet to Start' ? 'Upcoming' : 'Join Now'}</div>
                            <div className="letters">
                                {status === 'Yet to Start'
                                    ? <><span>U</span><span>P</span><span>C</span><span>O</span><span>M</span><span>I</span><span>N</span><span>G</span></>
                                    : <><span>J</span><span>O</span><span>I</span><span>N</span><span>&nbsp;</span><span>N</span><span>O</span><span>W</span></>}
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
    );
};

export default StudentAnimatedBackground;
