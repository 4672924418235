import React from 'react';
import './QuestionCard.css';
import { useNavigate } from 'react-router-dom';

function QuestionCard({ name, question, questionId }) {
    const navigate = useNavigate();

    const handleReplyClick = () => {
      navigate(`/questions/${questionId}`); 
    };
  return (
    <div className="question-card">
      <div className="question-card-container">
        <div className="question-card-content">
          <div className="question-card-profile">
            <img
              className="profile-image"
              src="https://images.unsplash.com/photo-1564877398-c4d5b4c1d6b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
              alt={`${name}'s profile`}
            />
            <div className="profile-name">
              <p>{name}</p>
            </div>
          </div>
          <div className="question-description">
            <p>{question}</p>
          </div>
          <div className="button-reply-link-container">
            <div className="upvote-downvote-button-container">
              <div className="upvote-button">
                <p>Upvote</p>
              </div>
              <div className="downvote-button">
                <p>Downvote</p>
              </div>
            </div>
            <div className="reply-button" onClick={handleReplyClick}>
              <p>Reply</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionCard;
