import React, { useState } from "react";
import Modal from "react-modal";
import "./OtpModal.css";

const OtpModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [otp, setOtp] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(otp);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="otp-modal-content"
      overlayClassName="otp-modal-overlay"
      contentLabel="OTP Modal"
    >
      <h2 className="otp-modal-title">Verify Your OTP</h2>
      <p className="otp-modal-subtitle">Enter the 6-digit OTP sent to your email</p>
      <form onSubmit={handleSubmit} className="otp-form">
        <input
          className="otp-input"
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          maxLength="6"
          placeholder="Enter OTP"
          required
        />
        <button type="submit" className="otp-submit-btn">
          Verify OTP
        </button>
      </form>
    </Modal>
  );
};

export default OtpModal;
